<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="通知详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="loading">
        <div class="w-e-text article">
          <h1 class="title">{{ info.title }}</h1>

          <span>{{ info.published_at }}</span>

          <div
            class="content"
            v-html="info.content"
          />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findNotification } from '@/api/notification'

export default {
  name: 'ShowNotification',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      info: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    alreadyRead() {
      this.$emit('alreadyRead', this.id)
    },

    fetchData() {
      this.loading = true
      findNotification(this.id).then((res) => {
        if (res.code === 0) {
          this.alreadyRead()
          this.info = res.data
        }
        this.loading = false
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.article {
  padding: 0 50px;
  text-align: center;

  .content {
    width: 750px;
    margin: 30px auto;
    text-align: left;
  }
}
</style>
